import React, { useState, useCallback } from 'react';

import {
  useRegisterMutationMiddleware,
  UpdateParams,
  UseUpdateOptions,
  UpdateMutationFunction,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  ArrayField,
  SingleFieldList,
  useRecordContext,
  Labeled,
} from 'react-admin';

import {
  Button,
  Box,
} from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { get, isArray } from 'lodash';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import ChipStringField from './ChipStringField';

const Empty = () => '-';

const EditArrayInput = ({
  source,
  label,
  showEdit,
}: {
  showEdit: boolean
  source: string
  label: string
}) => {
  const [editable, setEditable] = useState(false);
  const record = useRecordContext();
  const defaultValue = get(record, source);
  const { resetField } = useFormContext();

  const handleChange = useCallback(() => {
    const newValueEditable = !editable;
    if (!newValueEditable) {
      resetField(source, {
        defaultValue: isArray(defaultValue) ? [...defaultValue] : defaultValue,
      });
    }
    setEditable(newValueEditable);
  }, [editable, resetField, defaultValue, source]);

  const callback = useCallback(async (
    resource: string | undefined,
    params: Partial<UpdateParams> | undefined,
    options: UseUpdateOptions | undefined,
    next: UpdateMutationFunction,
  ) => {
    const existingSuccessCallback = options?.onSuccess;
    const newSuccessCallback: UseUpdateOptions['onSuccess'] = existingSuccessCallback ? (...args) => {
      setEditable(false);
      return existingSuccessCallback(...args);
    } : () => {
      setEditable(false);
    };
    const newOptions = {
      ...options,
      onSuccess: newSuccessCallback,
    };
    return next(resource, params, newOptions);
  }, []);

  useRegisterMutationMiddleware<UpdateMutationFunction>(callback);
  if (!editable) {
    return (
      <Labeled label={label} fullWidth>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <ArrayField sortable={false} source={source}>
            <SingleFieldList linkType={false} empty={<Empty />}>
              <ChipStringField />
            </SingleFieldList>
          </ArrayField>
          {showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Labeled>
    );
  }

  return (
    <Labeled label={label} fullWidth>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <ArrayInput source={source} label="" fullWidth={false}>
          <SimpleFormIterator>
            <TextInput source="" label="" />
          </SimpleFormIterator>
        </ArrayInput>
        <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
          Cancel
        </Button>
      </Box>
    </Labeled>
  );
};

export default EditArrayInput;
