import { Card } from '@mui/material';

import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  SelectInput,
  SelectField,
} from 'react-admin';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';
import LinkField from '../layout/LinkField';
import SearchField from '../layout/SearchField';

import ColoredScoreField from '../../customFields/ColoredScoreField';
import ChipStringField from '../../customFields/ChipStringField';
import { productTypesChoices } from '../../constants/productTypes';
import ReferenceEntityField from '../../customFields/ReferenceEntityField';

export const ProductListBody = ({ children }: {children?: React.ReactElement}) => (
  <Datagrid bulkActionButtons={false} sx={boldDataGridStyle} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" />
    <ArrayField sortable={false} source="customIds" label="Custom Ids">
      <SingleFieldList linkType={false}>
        <ChipStringField />
      </SingleFieldList>
    </ArrayField>
    <TextField source="productNumber" />
    <SelectField source="productType" choices={productTypesChoices} />
    <ArrayField sortable={false} source="parties" label="Parties">
      <SingleFieldList linkType={false}>
        <ReferenceEntityField source="" chip />
      </SingleFieldList>
    </ArrayField>
    <ColoredScoreField source="riskScore" />
    {children}
  </Datagrid>
);

export const ProductFilters = [
  <SearchField source="productNumber" label="Product number" alwaysOn />,
  <SearchField source="customId" label="Custom Id" />,
  <SelectInput source="productType" choices={productTypesChoices} />,
];

const ProductList = () => (
  <List
    resource="products"
    filters={ProductFilters}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    exporter={false}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <ProductListBody />
    </Card>
  </List>
);

export default ProductList;
