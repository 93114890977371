import React, { useCallback, useMemo, useState } from 'react';
import {
  Identifier,
  InputHelperText,
  InputProps,
  RecordContextProvider,
  useInput,
} from 'react-admin';
import {
  Box,
  FormControl,
  IconButton,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import ReferenceEntityField from './ReferenceEntityField';
import { EntityTypes } from '../constants/entityTypes';
import EntitySelectionDialog from '../components/entities/EntitySelectionDialog';

const ReferenceEntityInputV2 = (props: InputProps) => {
  const {
    label,
    helperText,
    source,
  } = props;
  const idSource = source ? `${source}.id` : 'id';
  const typeSource = source ? `${source}.type` : 'type';
  const {
    id: fieldId,
    field: {
      onChange: onChangeId,
      value: valueId,
    },
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    ...props,
    source: idSource,
  });

  const {
    field: {
      onChange: onChangeType,
      value: valueType,
    },
  } = useInput({
    ...props,
    source: typeSource,
  });

  const renderHelperText = (helperText !== false && helperText !== undefined)
    || ((isTouched || isSubmitted) && invalid);

  const [open, setOpen] = useState(false);
  const handleStartEdit = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleSelect = useCallback((data: { id: Identifier, type: EntityTypes }) => {
    onChangeId(data.id);
    onChangeType(data.type);
    setOpen(false);
  }, [onChangeId, onChangeType]);
  const handleClear = useCallback(() => {
    onChangeId(null);
    onChangeType(null);
  }, [onChangeId, onChangeType]);

  const currentValue = useMemo(() => ({
    id: valueId,
    type: valueType,
  }), [valueId, valueType]);

  return (
    <>
      <FormControl
        fullWidth
        required={isRequired}
        error={(isTouched || isSubmitted) && invalid}
        variant="standard"
      >
        <FormLabel htmlFor={fieldId}>{label}</FormLabel>
        <Box id={fieldId} display="flex" flexDirection="row" gap={2} alignItems="center">
          <RecordContextProvider value={currentValue}>
            <ReferenceEntityField source="" linkType={false} emptyText="" />
          </RecordContextProvider>
          <Box>
            <IconButton size="small" onClick={handleStartEdit} color="primary">
              {valueId ? <EditIcon /> : <AddCircleOutlineIcon />}
            </IconButton>
            {!!valueId && (
              <IconButton size="small" onClick={handleClear} color="warning">
                <HighlightOffIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <FormHelperText>
          {renderHelperText && (
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          )}
        </FormHelperText>
      </FormControl>
      <EntitySelectionDialog
        currentValue={valueId}
        open={open}
        handleClose={handleClose}
        handleSelect={handleSelect}
        label={label}
      />
    </>
  );
};

export default ReferenceEntityInputV2;
