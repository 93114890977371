import React from 'react';

import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
} from 'react-admin';

import {
  Card, CardHeader, Divider, Grid,
} from '@mui/material';
import cleaner from '../../utilities/dataProvider/cleaner';
import { productTypesChoices } from '../../constants/productTypes';
import { ProductPartiesInputFields } from '../../customFields/ProductParties';

const ProductCreate = () => (
  <Create transform={cleaner}>
    <SimpleForm mode="all">
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="customId" label="Custom id" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="productNumber" fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput source="productType" choices={productTypesChoices} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceArrayInput label="Groups" source="groups" reference="groups" fullWidth>
            <SelectArrayInput optionText="name" fullWidth />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{
                color: 'black',
                fontWeight: 'bold',
                flexWrap: 'wrap',
              }}
              titleTypographyProps={{ variant: 'inherit' }}
              title="Parties"
            />
            <Divider />
            <ProductPartiesInputFields />
          </Card>
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default ProductCreate;
